<template>
  <div class="cultrue_content">
    <div class="cultreu_title">
      <h1>企业文化考核</h1>
      <button v-if="$route.query.num!=0" class="heavy" @click="heavy">重选同事</button>
    </div>
    <div class="explain">
      <p>考核填写说明</p>
      <p>1、请对以下同事进行认真评选，请勾选出符合本条企业文化的同事</p>
      <p>2、当所得分达到4分、5分时，需要有相应的事迹说明</p>
    </div>
    <div class="colleague_topic">
      <div class="topic_title">
        <span v-for="(v,i) in setting" :key="i" :class="{active:settingIndex==i}">{{ v.name }}</span>
      </div>
      <div class="topic_content">
        <div>
          <p v-for="(v,i) in setting[settingIndex].item" v-show="i===itemIndex" :key="i" class="question_title">{{ v.question }}（{{ v.score }}分）</p>
          <el-checkbox-group v-model="answer" @change="checkTmpStaff">
            <!-- <el-checkbox v-for="(v,i) in checkStaff" :key="i" :label="v">{{ v.employee_realname }}</el-checkbox> -->
            <!-- <el-checkbox v-for="(v,i) in checkStaff" :key="i" :label="v" :checked="v.checkeds" @change="myFun(i)"> -->
            <el-checkbox v-for="(v,i) in checkStaff" :key="i" :label="v" @change="myFun(i)">
              <template>
                <div class="checkbox_content">
                  <div class="checkbox_content_text">
                    {{ v.employee_realname }}
                  </div>
                  <el-input
                    v-show="showCase && answer.find(item => item.employee_id === v.employee_id)"
                    v-model="v.caseText"
                    type="textarea"
                    :rows="3"
                    placeholder="请输入事例"
                  >
                  </el-input>
                  <span v-show="showCase && answer.find(item => item.employee_id === v.employee_id)&&v.caseText.length<setting[settingIndex].item[itemIndex].mini_word_num" class="checkbox_text">* 最少输入{{ setting[settingIndex].item[itemIndex].mini_word_num }}个字</span>
                  <!-- <span v-show="showCase && index.filter(v=>v===i).length || v.caseText!=''" class="checkbox_text">* 最少输入{{ setting[settingIndex].item[itemIndex].mini_word_num }}个字</span> -->
                </div>
              </template>
            </el-checkbox>
          </el-checkbox-group>
        </div>

      </div>
      <div class="btn_group">
        <el-button v-show="!(itemIndex==0 && settingIndex==0)" type="info" :disabled="isButton" @click="froward">上一步</el-button>
        <el-button type="info" :disabled="isButton" @click="next">下一步</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { getAnswerNext, toChoseApi, getCulture } from '@/api/assessment'
export default {
  data() {
    return {
      answer: [],
      myArr: [],
      num: 0,
      setting: [],
      settingIndex: 0,
      itemIndex: 0,
      checkStaff: [],
      page: 0,
      submitArr: [
        {
          'name': '追求简单1',
          'name_sort': 0, // 大题排序
          'question': '题目1：你做好了吗',
          'question_sort': 1, // 小题排序
          'score': 1, // 分数
          'write_case': 1, // 等于1显示文本框
          'mini_word_num': 0, // 文本框填的字数
          'page': 1,
          'selected': [
            {
              'employ_id': 12,
              'real_name': '胡斯乐',
              'case': ''
            },
            {
              'employ_id': 13,
              'real_name': '陈泉',
              'case': ''
            }

          ]
        }
      ],
      caseText: '',
      showCase: false,
      selectedAllStaff: [],
      username: '',
      exam_date: '',
      index: [],
      onceSetting: [],
      isButton: true
    }
  },
  computed: {
    newCheckStaff() {
      return JSON.parse(JSON.stringify(this.checkStaff))
    }
  },
  watch: {
    itemIndex: {
      handler(newVal, oldVal) {
        setTimeout(() => {
          this.isButton = false
        }, 1000)
        if (newVal < 3) {
          this.showCase = false
        } else {
          setTimeout(() => {
            if (!this.setting[this.settingIndex].item[newVal]) {
              return
            }
            if (this.setting[this.settingIndex].item[newVal].write_case) {
              this.showCase = true
            } else {
              this.showCase = false
            }
          }, 1000)
        }
      },
      immediate: true
    },
    newCheckStaff: {
      handler(newVal, oldVal) {
        if (!this.checkStaff.length) {
          return
        }
        this.checkStaff.forEach((v, i) => {
          if (v.caseText.length > 0) {
            const isPush = this.answer.find(e => {
              return e.employee_id === v.employee_id
            })
            if (!isPush) {
              this.answer.push(v)
            }
          }
        })
      },
      immediate: true
    },
    '$route'(to, from) {
      if (from.path == '/assessment/exhibition') {
        this.page--
        this.isButton = false
      }
    }
  },
  mounted() {
    document.title = sessionStorage.getItem('name_zl') + '-企业文化考核'
  },
  created() {
    this.isSubmint()
    this.exam_date = sessionStorage.getItem('DATE')
    const result = JSON.parse(sessionStorage.getItem('staffList'))
    this.getNextData()
    this.setting = result.setting
    this.selectedAllStaff = result.staff_list
  },
  activated() {
    this.isSubmint()
    if (sessionStorage.getItem('isAgain') === 1 || sessionStorage.getItem('isAgain') === '1') {
      this.settingIndex = 0
      this.itemIndex = 0
      this.page = 0
      this.exam_date = sessionStorage.getItem('DATE')
      const result = JSON.parse(sessionStorage.getItem('staffList'))
      this.getNextData()
      this.setting = result.setting
      this.selectedAllStaff = result.staff_list
    }
  },
  methods: {
    // 是否已答过企业文化
    isSubmint() {
      const date = new Date()
      const y = date.getMonth() == 0 ? date.getFullYear() - 1 : date.getFullYear()
      const m = date.getMonth() == 0 ? 12 : date.getMonth() < 10 ? '0' + date.getMonth() : date.getMonth()
      const params = {
        exam_date: y + '-' + m
      }
      getCulture(params).then(res => {
        if (res.errNo === 1004) {
          console.log('本人已答过当月文化考核')
          this.$router.push({
            path: '/assessment/assuccess'
          })
        }
      })
    },
    myFun(index) {
      if (this.showCase) {
        const h = this.index.find(item => item === index)
        const key = this.index.indexOf(h)
        if (key === -1) {
          this.index.push(index)
        } else {
          this.index.splice(key, 1)
        }
      }
    },
    // 重选同事
    heavy() {
      this.$router.push({ path: '/assessment', query: { exam_date: this.exam_date, isAgain: 1 }})
    },
    //   选中同事
    checkTmpStaff(val) {
      // val.forEach((v, i) => {
      //   v.checkeds = true
      // })
    },
    //   上一步
    froward() {
      this.isButton = true
      const params = {
        page: this.page,
        name: 'answer',
        exam_date: this.exam_date
      }
      toChoseApi(params).then(res => {
        this.page--
        if (!res) {
          this.$router.go(-1)
          return
        }
        // 默认勾选上一步选中
        const staff_list = res.result.staff_list
        staff_list.forEach(item => {
          if (item.case === 1) {
            this.answer.push(item)
          } else if (!item.caseText) {
            this.$set(item, 'caseText', '')
          }
        })
        this.checkStaff = staff_list
        if (this.itemIndex === 0) {
          this.settingIndex--
          this.itemIndex = this.setting[this.settingIndex].item.length
        }
        this.itemIndex--
      })
    },
    // 下一步
    next() {
      this.isButton = true
      const settingItem = this.setting[this.settingIndex]
      const topicItem = this.setting[this.settingIndex].item[this.itemIndex]
      let continues = true
      if (this.showCase) {
        if (this.answer.length > 0) {
          this.answer.forEach(v => {
            if (v.caseText.length < topicItem.mini_word_num) {
              this.$message.error(`字数最少${topicItem.mini_word_num}字`)
              continues = false
              return
            }
          })
        }
        if (!continues) {
          return
        }
      }
      this.index = []
      this.page++
      const tmpSelected = []
      this.answer.forEach(v => {
        tmpSelected.push({
          employee_id: v.employee_id,
          employee_realname: v.employee_realname,
          caseText: v.caseText
        })
      })
      const obj = {}
      const selected = tmpSelected.reduce((cur, next) => {
        obj[next.employee_id] ? '' : obj[next.employee_id] = true && cur.push(next)
        return cur
      }, [])
      const data = {
        name: settingItem.name,
        name_sort: settingItem.sort,
        question: topicItem.question,
        question_sort: topicItem.sort,
        score: topicItem.score,
        write_case: topicItem.write_case,
        mini_word_num: topicItem.mini_word_num,
        page: this.page,
        selected,
        staff_list: this.checkStaff,
        all_staff_list: this.selectedAllStaff
      }
      sessionStorage.setItem('tmpStaff', JSON.stringify(this.answer))
      const params = {
        page: this.page,
        name: 'answer',
        data,
        exam_date: this.exam_date
      }
      getAnswerNext(params).then(res => {
        if (res.errNo === 0) {
          this.caseText = ''
          this.getNextData(settingItem, this.answer)
          this.answer = []
        }
      })
      this.itemIndex++
      if (this.itemIndex >= this.setting[this.settingIndex].item.length && this.settingIndex < this.setting.length - 1) {
        this.settingIndex++
        this.itemIndex = 0
        this.checkStaff = this.selectedAllStaff
      } else if (this.itemIndex >= this.setting[this.settingIndex].item.length && this.settingIndex === this.setting.length - 1) {
        this.itemIndex--
        // this.page--
        if (this.page >= 20) {
          this.page = 20
        }
        this.$router.push({ path: '/assessment/exhibition', query: { page: this.page }})
      }
    },
    // 获取下一条数据
    getNextData(settingItem, selected) {
      const params = {
        name: 'answer',
        page: this.page + 1,
        exam_date: this.exam_date,
        selected: JSON.stringify(selected)
      }
      toChoseApi(params).then(res => {
        this.checkStaff = res.result.staff_list
        this.myArr = []
        res.result.staff_list.forEach(e => {
          if (e.case === 1) {
            if (!e.caseText) {
              this.$set(e, 'caseText', '')
            }
            this.answer.push(e)
          } else if (!e.caseText) {
            this.$set(e, 'caseText', '')
          }
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.question_title {
  font-size: 16px;
  font-weight: bold;
}
/deep/ .el-button {
  color: #000;
  background: #dddddd;
  border: 0;
}
.checkbox_text{
  color: #f00;
  font-size: 14px;
}
.heavy {
  width: 110px;
  height: 30px;
  background: #f00;
  border: none;
  border-radius: 30px;
  color: #fff;
  float: right;
  cursor: pointer;
}
.checkbox_content_text {
  width: 60px;
}
.checkbox_content {
  display: flex;
}
.cultrue_content{
    width: 1000px;
    margin: 50px auto;
}
.explain {
    margin-top: 40px;
    text-align: left;
    background-color: #e5e5e5;
    padding: 10px;
}
.colleague_topic{
    margin-top: 30px;
    border: 1px solid #ccc;
    .topic_title {
        // display: flex;
        // justify-content: space-between;
        text-align: left;
        span {
            display: inline-block;
            height: 50px;
            width: 20%;
            background-color: #e5e5e5;
            text-align: center;
            line-height: 50px;
            font-size: 20px;
            margin-right: 6.6%;
            &:hover {
                cursor: pointer;
            }
        }
        span:last-child {
            margin-right: 0;
        }
        .active{
            background-color: #f00;
            color: #fff;
        }
    }
    .topic_content {
        margin-top: 20px;
        padding: 0 30px;
        // display: flex;
        p {
            text-align: left;
        }
    }
}
.btn_group {
    margin: 20px 0;
}
/deep/ .el-checkbox-group {
    // width: 100%;
    display: flex;
    flex-direction: column;
}
// /deep/ .el-checkbox {
//   width: 100%;
// }
/deep/ .el-textarea{
  margin-left: 30px;
  width: 500px;
}
</style>
