import request from '../utils/request'

// 获取员工列表
export function getCulture(params) {
  return request({
    url: '/hrm/Manage/cultureAssessSelectPage',
    method: 'post',
    params
  })
}
// 选好了
export function toChoseApi(params) {
  return request({
    url: '/hrm/Manage/cultureAssessAnswerPage',
    method: 'GET',
    params
  })
}
// 考核上一步
export function getAnswerForward(params) {
  return request({
    url: '/hrm/manage/getCultureAnswerDetail',
    method: 'GET',
    params
  })
}

// 考核下一步
export function getAnswerNext(params) {
  return request({
    url: '/hrm/manage/setCultureAnswerDetail',
    method: 'POST',
    params
  })
}

// 获取题目
export function getQuestionApi(params) {
  return request({
    url: '/hrm/Manage/getNextCultureAnswer',
    method: 'GET',
    params
  })
}

// 确认分数
export function getCultureResultApi(params) {
  return request({
    url: '/hrm/Manage/cultureAssessResult',
    method: 'GET',
    params
  })
}
// 提交
export function toSubmit(params) {
  return request({
    url: '/hrm/Manage/actionCultureAssess',
    method: 'GET',
    params
  })
}
